import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import useApplicationsStore from "~/stores/apps/useApplicationsStore";
import useContractsStore from "~/stores/contracts/useContractsStore";
import useSearchStore from "~/stores/search/useSearchStore";
import { Tabs } from "~/router/tabs";

const isOpen = ref<boolean>(false);

/** логика открытия диалога настройки тегов */
export function useTagsEditor() {

  const route = useRoute();

  const searchStore = useSearchStore();
  const { needReload: searchNeedReload } = storeToRefs(searchStore);

  function openTagEditor() {
    isOpen.value = true;
  }

  function setNeedReloadData() {
    if (route.meta.searchGroup) searchNeedReload.value = true;
  }

  function closeTagEditor() {
    isOpen.value = false;
  }

  return {
    isOpen,
    setNeedReloadData,
    openTagEditor,
    closeTagEditor,
  }
}
